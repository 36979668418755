// local
// const BASE_URL = "http://192.168.21.19:5000/node-api/";
// const DEFAULT_KEY = 'c26436e7-e388-4088-adc1-563b6f496907'

//demo
// const BASE_URL = "https://admin.diameco.iossmlm.com/node-api/";
// const DEFAULT_KEY = '5db530f2-bf7b-4f0c-83a8-6371884aae91'

//live
const BASE_URL = "https://admin.diameco.biz/node-api/";
const DEFAULT_KEY = 'c26436e7-e388-4088-adc1-563b6f496907'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
